.about-container {
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  display: flex;
}

.about-container .text {
  padding-left: 25px;
  padding-right: 25px;
  font-family: Lobster, cursive;
  display: flex;
}

.about-container .text h1 {
  color: #35a6c0;
  word-break: break-word;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 50px;
}

.about-container .keyword {
  color: #7bcbbc;
  cursor: pointer;
  font-family: Staatliches, cursive;
  text-decoration: underline;
}

@media (min-width: 1500px) {
  .about-container .text {
    margin-top: 150px;
    margin-left: 150px;
  }

  .about-container .text h1 {
    font-size: 80px;
  }
}

/*# sourceMappingURL=index.0105a53d.css.map */
