.about-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-container .text {
  font-family: 'Lobster', cursive;
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
}

.about-container .text h1 {
  font-size: 50px;
  color: #35a6c0;
  margin-top: 50px;
  margin-bottom: 50px;
  word-break: break-word;
}

.about-container .keyword {
  font-family: 'Staatliches', cursive;
  color: #7bcbbc;
  text-decoration: underline;
  cursor: pointer;
}

@media (min-width: 1500px) {
  .about-container .text {
    margin-left: 150px;
    margin-top: 150px;
  }
  .about-container .text h1 {
    font-size: 80px;
  }
}
